<template>
  <div>
    <v-form>
      <p class="mb-3">
        For the reaction <chemical-latex content="A->products," /> time and concentration data were
        collected and tabulated. Also shown below are simplified sketches of the data.
      </p>

      <v-simple-table style="max-width: 193px" class="mb-6">
        <thead>
          <tr>
            <td><stemble-latex content="$\text{[A] (M)}$" /></td>
            <td><stemble-latex content="$\text{t (min)}$" /></td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>0.850</td>
            <td>0.00</td>
          </tr>
          <tr>
            <td>0.611</td>
            <td>30.0</td>
          </tr>
          <tr>
            <td>0.439</td>
            <td>60.0</td>
          </tr>
          <tr>
            <td>0.316</td>
            <td>90.00</td>
          </tr>
        </tbody>
      </v-simple-table>

      <p class="pl-4 mb-6">
        <v-img style="max-width: 659px" :src="imageName" />
      </p>

      <p class="mb-2">a) What is the reaction order for the reaction? Please enter a number.</p>

      <calculation-input
        v-model="inputs.order"
        class="mb-5"
        prepend-text="$\text{Order:}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">b) What is the rate constant for the reaction?</p>

      <calculation-input
        v-model="inputs.rateConstant"
        class="mb-5"
        prepend-text="$\text{k:}$"
        append-text="$\text{Units not shown}$"
        :disabled="!allowEditing"
      />

      <p class="mb-n3">c) What are the units for the rate constant?</p>

      <v-radio-group v-model="inputs.units" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in optionsShuffled"
          :key="'pt-3-' + option.value"
          class="my-1"
          :value="option.value"
          :label="option.text"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import CalculationInput from '../inputs/CalculationInput.vue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default {
  name: 'UA_Quiz1Question2',
  components: {ChemicalLatex, StembleLatex, CalculationInput},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        order: null,
        rateConstant: null,
        units: null,
      },
      optionsUnits: [
        {text: '$\\text{M}^{-2} ~ \\text{s}^{-1}$', value: 'perM2perSec'},
        {text: '$\\text{M}^{-1} ~ \\text{s}^{-1}$', value: 'perMperSec'},
        {text: '$\\text{s}^{-1}$', value: 'perSec'},
        {text: '$\\text{M} ~ \\text{s}^{-1}$', value: 'MperSec'},
        {text: '$\\text{M}^2 ~ \\text{s}^{-1}$', value: 'M2perSec'},
      ],
    };
  },
  computed: {
    imageName() {
      return '/img/assignments/kinPlots2.png';
    },
    seed() {
      return this.$gate.user?.id || 1;
    },
    optionsShuffled() {
      return seededShuffle(this.optionsUnits, this.seed);
    },
  },
};
</script>
